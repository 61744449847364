import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAimBaEsWaU12WZu60pS4Byd4gjV2lKfL4",
  authDomain: "gmaps-afdb9.firebaseapp.com",
  projectId: "gmaps-afdb9",
  storageBucket: "gmaps-afdb9.appspot.com",
  messagingSenderId: "561097102967",
  appId: "1:561097102967:web:45a882a50c405c0a221872"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;


